.about {
    min-height: 624px;
    display: flex;
}

.about__image {
    margin: 80px 56px 80px 0;
}

.about__container {
    margin-top: 131px;
    margin-right: 112px;
}

.about__title {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 1.15;
    margin: 0;
    margin-bottom: 24px;
}

.about__text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.33;
    margin: 0;
}

@media screen and (max-width:1100px){
    .about {
        min-height: 354px;
    }
    
    .about__image {
        max-height: 232px;
        max-width: 232px;
        margin: 61px 32px 61px 0;
    }
    
    .about__container {
        margin-top: 40px;
        margin-right: 0;
    }
    
    .about__title {
        font-size: 30px;
        line-height: 1.33;
        margin-bottom: 16px;
    }
}

  @media screen and (max-width:550px){
    .about {
        flex-direction: column;
        align-items: center;
    }
    
    .about__image {
        max-height: 272px;
        max-width: 272px;
        margin: 32px 0 24px;
    }
    
    .about__container {
        margin: 0 0 128px;
    }
}