.news-list {
    box-sizing: border-box;
    min-height: 282px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F5F6F7;
    padding-top: 80px;
    padding-bottom: 80px;
}

.news-list_type_saved {
    padding: 62px 102px;
}

.news-list__title {
    align-self: flex-start;
    margin: 0;
    margin-bottom: 64px;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 1.15;
}

.news-list__title_type_not-found {
    align-self: center;
    font-size: 26px;
}

.news-list__container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
}

.news-list__show-more {
    display: block;
    background-color: #fff;
    border: none;
    border-radius: 80px;
    padding: 20px 98px;
    margin-top: 64px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.33;
}

.news-list__show-mor_not-visible {
    display: none;
}

.news-list__show-more:hover {
    background-color: #E8E8E8;
}

.news-list__not-found-icon {
    background-image: url('../../images/icons/not-found-icon.svg');
    min-height: 96px;
    min-width: 96px;
    margin-bottom: 24px;
}

.news-list__not-found-text {
    max-width: 250px;
    text-align: center;
    color: #B6BCBF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.33;
    margin: 0;
    margin-top: 16px;
}

@media screen and (max-width:950px){
    .news-list {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    
    .news-list_type_saved {
        padding: 32px 40px;
    }
    
    .news-list__title {
        margin-bottom: 32px;
        font-size: 30px;
        line-height: 1.13;
    }
    
    .news-list__title_type_not-found {
        align-self: center;
        font-size: 26px;
    }
    
    .news-list__container {
        grid-gap: 8px;
    }
    
    .news-list__show-more {
        padding: 16px 74px;
        margin-top: 32px;
    }
}

@media screen and (max-width:620px){
    .news-list__container {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    .news-list_type_saved {
        padding: 24px 16px 13px;
    }
}

@media screen and (max-width:375px){
    .news-list__container {
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }
}