@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/inter-400-regular.woff2) format("woff2"),
       url(./fonts/inter-400-regular.woff) format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url(./fonts/inter-500.woff2) format("woff2"),
       url(./fonts/inter-500.woff) format("woff");
}
