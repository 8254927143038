@import url(./vendor/normalize.css);
@import url(./vendor/Inter.css);
@import url(./vendor/Roboto.css);
@import url(./vendor/Roboto-slab.css);
@import url(./vendor/Source-sans.css);

body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
