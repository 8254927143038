.preloader {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.preloader__circle {
	display: block;
	width: 74px;
	height: 74px;
	border: 4px solid #444;
	border-bottom-color: #888;
	
	border-radius: 50%;
	
	animation: spin .75s infinite linear;
}

.preloader__text {
    color: #B6BCBF;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.33;
    margin: 0;
    margin-top: 24px;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}