.popup {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    min-width: 100vw;
    min-height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: visibility 0s linear 0.5s, opacity 0.5s linear;
    z-index: 0;
}

.popup_visible {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
}

.popup__container {
    position: relative;
    min-width: 430px;
    min-height: 380px;
    box-sizing: border-box;
    color: #1A1B22;
    background-color: white;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    padding: 34px 36px 28px;
}

.popup__container_type_success {
    min-height: 182px;
}

.popup__close-button {
    min-width: 24px;
    min-height: 24px;
    position: absolute;
    top: -38px;
    right: -38px;
    background-color: transparent;
    background-image: url(../../images/icons/close_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: none;
    transition: opacity .3s ease-in-out;
}

.popup__close-button:hover {
    opacity: .6;
    cursor: pointer;
}

.popup__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 1.2;
    margin: 0 0 22px;
}

.popup__title_type_success {
    max-width: 315px;
}

.popup__form {
    display: flex;
    flex-direction: column;
}

.popup__input-title {
    color: #2F71E5;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.25;
    margin: 0;
    margin-bottom: 9px;
}

.popup__input {
    color: black;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.21;
    padding-bottom: 5px;
    border: none;
    border-bottom: 1px rgba(0,0,0,.2) solid;
    margin-bottom: 3px;
}

.popup__input:focus {
    outline: none;
    border-color: rgba(0, 0, 0, 1);
}

.popup__input::placeholder {
    color: black;
    opacity: 0.2;
}

.popup__submit-button {
    min-height: 50px;
    color: white;
    background-color: #2F71E5;
    box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
    border: none;
    border-radius: 100px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.33;
    text-align: center;
    cursor: pointer;
    transition: opacity .3s ease-in-out;
}

.popup__submit-button:hover {
    opacity: .8;
}

.popup__submit-button:disabled {
    background: #E6E8EB;
    border-radius: 100px;
}

.popup__error {
    min-height: 14px;
    color: red;
    font-size: 12px;
    line-height: 1.2;
    padding: 0;
    margin-bottom: 13px;
}

.popup__error:last-of-type {
    margin-bottom: 5px;
}

.popup__general-error-message {
    margin: 0 auto;
}

.popup__error_visible {
    opacity: 1;
}

.popup__redirect {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin:16px 0 0;
}

.popup__redirect-text {
    margin: 0;
    margin-right: 5px;
}

.popup__redirect-button {
    color: #2F71E5;
    background: white;
    border: none;
    padding: 0;
    cursor: pointer;
}

@media screen and (max-width:550px){   
    .popup__container {
        position: relative;
        min-width: 100vw;
        min-height: 100vh;
        top: 56px;
    }
    
    .popup__close-button {
        top: -40px;
        right: 16px;
    }
    
    .popup__submit-button {
        min-height: 64px;
    }
    
    .popup__submit-button:hover {
        opacity: .8;
    }
}